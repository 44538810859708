import Axios from "axios";
let token = window.localStorage.getItem("accessToken") || "";

const axios = Axios.create({

  baseURL: `https://api-arab.b2gsoft.com/api/v1`,

  // baseURL: `http://82.197.69.238:4341/api/v1`,
  // baseURL: `http://192.168.0.242:4341/api/v1`, 


  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,

  },

});

export default axios;
